<template>
  <div class="questionnaire-prompt-container">
    <k-panel :hasContentToggle="false">
      <template #body>
        <section class="header-container">
        <h2 class="prompt-container">
          <span class="prompt-number">{{ prompt.number }}.</span>
          <span class="prompt" v-html="getMarkdown(prompt.prompt)"></span>
        </h2>
        <button
          v-if="(responseType === 'LONG_TEXT' || responseType === 'SHORT_TEXT') && enableAiFeedback"
          type="button"
          class="modal-default-button btn btn-outlined feedback-modal-button"
          @click="openFeedbackModal">
            <img :src="chatbotImage" class="chatbot-icon-small" alt="KATE bot avatar"/>
          Ask KATE for Feedback
        </button>
      </section>
      <section class="response">
        <k-text-editor
          v-if="responseType === 'LONG_TEXT' || responseType === 'SHORT_TEXT'"
          v-model="currentResponse"
          :id="`promptResponse-${prompt.id}`"
          :maxLength="maxTextLength"
          :placeholder="textPlaceholder"
          :read-only="readOnly"
          :disable-toolbar="disableTextToolbar"
        ></k-text-editor>
        <k-percentage-slider
          v-if="responseType === 'QUANTITATIVE_PERCENT'"
          :read-only="readOnly"
          v-model="currentResponse"
        ></k-percentage-slider>
        <k-number-choice
          v-if="responseType === 'QUANTITATIVE_VALUE'"
          :read-only="readOnly"
          v-model="currentResponse"
        ></k-number-choice>
        <k-multi-choice
          v-if="responseType === 'MULTIPLE_CHOICE'"
          v-model="currentResponse"
          :readOnly="readOnly"
          :choices="prompt.answers.choices"
          :choiceAmount="prompt.answers.choice_amount"
        ></k-multi-choice>
      </section>
    </template>
  </k-panel>

  <questionnaire-feedback-modal
      :showModal="showFeedbackModal"
      :prompt="prompt"
      :currentResponse="currentResponse"
      @close="closeFeedbackModal"
    />
  </div>
</template>

<style>
.prompt-container span p {
  color: var(--kate-type-light);
}
</style>

<style scoped>
.prompt {
  width: 95%;
}

.heading-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.prompt-container {
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.35em;
}

.prompt-number {
  padding-right: 5px;
  min-width: 30px;
}

.prompt-container span p {
  color: var(--kate-type-light);
}

.feedback-modal-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.chatbot-icon-small {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<script>
import KTextEditor from '@base-components/k-text-editor.vue';
import KPanel from '@base-components/k-panel.vue';
import KPercentageSlider from '@base-components/k-percentage-slider.vue';
import KNumberChoice from '@base-components/k-number-choice.vue';
import KMultiChoice from '@base-components/k-multi-choice.vue';
import { markdown } from '@utils/maths-markdown';
import { importImage } from '@utils/dynamic-image-importer';
import QuestionnaireFeedbackModal from '../feedback/components/questionnaire-feedback-modal.vue';

export default {
  components: {
    KPanel,
    KTextEditor,
    KPercentageSlider,
    KNumberChoice,
    KMultiChoice,
    QuestionnaireFeedbackModal,
  },

  props: {
    prompt: {
      type: Object,
    },
    previousResponse: {
      type: [Array, String, Number],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disableTextToolbar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentResponse: undefined,
      showFeedbackModal: false,
    };
  },

  mounted() {
    this.currentResponse = this.previousResponse;
  },

  watch: {
    currentResponse() {
      this.$emit('update', this.currentResponse);
    },
  },

  computed: {
    chatbotImage() {
      return importImage('icons/kate-bot-chat-colours.svg');
    },
    maxValue() {
      if (this.prompt.response_type === 'QUANTITATIVE_PERCENT') {
        return 100;
      }
      if (this.prompt.response_type === 'QUANTITATIVE_VALUE') {
        return 10;
      }
      return undefined;
    },
    maxTextLength() {
      return this.prompt.response_type === 'SHORT_TEXT' ? 512 : 10000;
    },
    textPlaceholder() {
      if (this.prompt.response_type === 'LONG_TEXT') {
        return 'Enter your response here...';
      }
      if (this.prompt.response_type === 'SHORT_TEXT') {
        return `Enter your response here - only a short response is required (max ${this.maxTextLength} characters)`;
      }
      return undefined;
    },
    responseType() {
      return this.prompt.response_type || this.prompt.responseType;
    },
    enableAiFeedback() {
      return this.currentResponse && this.currentResponse?.trim().length > 0 && this.prompt.ai_feedback_enabled;
    },
  },

  methods: {
    openFeedbackModal() {
      this.showFeedbackModal = true;
    },
    closeFeedbackModal() {
      this.showFeedbackModal = false;
    },
    clearResponse() {
      this.currentResponse = undefined;
    },
    getMarkdown(str) {
      return markdown(str);
    },
  },
};
</script>
